import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, TextField } from '@material-ui/core';
import Cookies from 'universal-cookie';
import './SectionSubscribe.scss';
import './mobile/index.scss';
import { vipBg2 } from '@assets/img';
import axios from 'axios';
import { Trans } from '@i18n';
import { userActions } from '@redux/actions';
import { toast } from '../../helpers';
import { Btn, LazyBackground } from '..';

type SectionSubscribeState = any;
class SectionSubscribe extends Component<any, SectionSubscribeState> {
  constructor(props: {}) {
    super(props);
    this.subscribe = this.subscribe.bind(this);
  }

    state = {
      helperText: '',
      value: '',
      error: false,
      disabled: true,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      response: false,
    };

    componentDidUpdate(prevProps: {}, prevState: SectionSubscribeState, snapshot: any) {
      if ((this.props as any).language !== (prevProps as any).language) {
        this.forceUpdate();
      }
    }

    subscribe() {
      this.setState((prevstate: any) => ({
        ...prevstate,
        response: 'loading',
      }));
      const { dispatch } = this.props;
      const cookies = new Cookies();
      const options = {
        path: '/',
      };
      cookies.set('email', this.state.value, options);
      dispatch(userActions.setEmail(this.state.value));
      try {
        axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/dashboard/subscribe`, {
          email: this.state.value,
          locale: (this.props as any).language === 'ru' ? 'ru' : 'en',
        }, {
          headers: {
            'X-localization': (this.props as any).language === 'ru' ? 'ru' : 'en',
          },
        }).then((res: any) => {
          if (res.status === 200) {
            this.setState((prevstate: any) => ({
              ...prevstate,
              response: true,
              value: '',
            }));
            toast('success', 'Подписка на рассылку прошла успешно');
          } else if (res.response !== undefined) {
            this.setState((prevstate: any) => ({
              ...prevstate,
              response: false,
            }));
            toast('error', res.response.data.message);
          } else {
            this.setState((prevstate: any) => ({
              ...prevstate,
              response: false,
            }));
            toast('error', "Что-то пошло не так.");
          }
        }, (err) => {
          if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.email[0]) {
            const error = err.response.data.errors.email[0];
            toast('error', error);
          } else {
            toast('error', "Что-то пошло не так.");
          }
          this.setState((prevstate: any) => ({
            ...prevstate,
            response: false,
          }));
        });
      } catch (e) {
        this.setState((prevstate: any) => ({
          ...prevstate,
          response: false,
        }));
        console.log(e);
      }
    }

    render() {
      return (
        <LazyBackground className="subscribe-section" src={vipBg2}>
          <Container>
            <Grid container direction="row" justify={((this.props as any).windowWidth <= 1024) ? 'flex-start' : 'center'} alignItems="center">
              <Grid item xs={12} sm={8} md={12} className="subscribe-section-wrap">
                <Grid md={6} item className="subscribe-section-titleBlock">
                  <h3 className="subscribe-section-title"><Trans>Подпишись и получи выгодное предложение</Trans></h3>
                </Grid>
                <Grid className="subscribe-form" md={4} item>
                  <form className="subscribe_form_fix" noValidate autoComplete="off">
                    <div className="subscribe-input">
                      <div className="subscribe-description">
                        <Trans>Мы постоянно улучшаем наш сервис, предлагаем скидки и акции. Подпишись, чтобы узнавать о них первым. Мы не любим получать спам и сами его не рассылаем.</Trans>
                      </div>
                      <TextField
                        className="subscribe-textField"
                        label={<label htmlFor="section_subscribe__email">Email</label>}
                        id="section_subscribe__email"
                        name="email"
                        helperText={this.state.helperText}
                        error={this.state.error}
                        value={this.state.value}
                        required
                        onChange={(event) => {
                          if (this.state.reg.test(String(event.target.value).toLowerCase())) {
                            this.setState({
                              value: event.target.value, error: false, helperText: '', disabled: false,
                            });
                          } else {
                            this.setState({
                              value: event.target.value, error: true, helperText: <Trans>Неверный email</Trans>, disabled: true,
                            });
                          }
                        }}
                        onKeyPress={(event) => {
                          if (event.key == 'Enter') {
                            event.preventDefault();
                            if (this.state.reg.test(String(this.state.value).toLowerCase())) {
                              this.subscribe();
                            }
                            return false;
                          }
                        }}
                      />
                    </div>
                  </form>
                </Grid>
                <Grid md={2} item>
                  <Btn
                    color="white"
                    flex
                    className="button_fix_main"
                    loading={String(this.state.response) === 'loading'}
                    onClick={() => {
                      this.subscribe();
                    }}
                    disabled={this.state.disabled}
                  >
                    {((value) => {
                      switch (value) {
                        case true: return (
                          <>
                            <Trans>Вы подписаны</Trans>
                            <i className="fa fa-caret-right ml2" />
                          </>
                        );
                        case false: return (
                          <>
                            <Trans>Подписаться</Trans>
                            <i className="fa fa-caret-right ml2" />
                          </>
                        );
                        //@ts-ignore
                        case 'loading': return (<Trans>Подписка</Trans>);
                        default: return null;
                      }
                    })(this.state.response)}
                  </Btn>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </LazyBackground>
      );
    }
}
const mapStateToProps = (state: any) => {
  const { windowWidth, language } = state.info;
  return {
    windowWidth,
    language,
  };
};
export default connect(mapStateToProps)(React.memo(SectionSubscribe));
